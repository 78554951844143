<template lang="pug">

	extends ./table.pug

	block tableTH
		template(v-slot:cell(createdAt)='data')
			p.mb-2.b3(v-if="data.item.id") {{ data.item.id }}
			p.mb-0.b4.text-grey.text-nowrap(v-if="data.item.createdAt") {{ data.item.createdAt | dateDDMMMYYYY }}

		template(v-slot:cell(project)='data')
			a.b3.text-primary(v-if='data.item.plan && data.item.plan.project' target='_blank' :href='data.item.plan.project.link ? data.item.plan.project.link : data.item.plan.project.linkHashId') {{ data.item.plan.project.title }}
			a.b3.text-primary(v-else-if='data.item.project' target='_blank' :href='data.item.project.link ? data.item.project.link : data.item.project.linkHashId') {{ data.item.project.title }}

		template(v-slot:cell(price)='data')
			span(v-if='data.item.info && data.item.info.plan' v-html="data.item.info.plan.priceWithCurrency()")

		template(v-slot:cell(account)='data')
			p.b3.mb-0 {{ data.item.account.id }}

		template(v-slot:cell(profit)='data')
			.text-nowrap(v-if='data.item.info.toPartner' v-html="data.item.info.toPartner.priceWithCurrency()")
			.text-nowrap(v-else-if='data.item.info.toPartnerRUB' v-html="data.item.info.toPartnerRUB.priceWithCurrency()")

		template(v-slot:cell(status)='data')
			p.b3.mb-0.vuetable__status(v-if='data.item' :class="statusVariant(data.item)" :id="!isMobile ? `status-${data.item.id}` : ''" @click="isMobile ? $bvModal.show(`status-${data.item.id}`) : ''")
				span.b3(v-if='data.item.statusO') {{ data.item.statusO[`title${locale.toUpperCase()}`] }}
				span.b3(v-else) {{ data.item.status }}

			modal-tooltip(
				v-if='data.item.errorMessage != null'
				:id="`status-${data.item.id}`"
				:text="data.item.errorMessage"
				:textMobile="data.item.errorMessage"
			)
</template>

<script>
import { bus } from '@/main.js';
import { mapGetters, mapActions } from 'vuex';
import TableMixin from './TableMixin';
import TableSearch from './TableSearch';
import TablePagination from './TablePagination';
import TableShowMore from './TableShowMore';
import { locale } from '@/main';
import { STATUS } from '@/models/payment';

export default {
    name: 'FinanceIncomePartner',
    mixins: [TableMixin],
    props: {
        filters: {
            type: Object,
            default: () => ({}),
        },
    },
    components: {
        TableSearch,
        TablePagination,
        TableShowMore,
    },
    data() {
        return {
            sortBy: 'createdAt',
            searchKeys: ['createdAt'],
            noLocalSorting: true,
            sorting: ctx => {
                let data = {};

                data[`order[${ctx.sortBy}]`] = ctx.sortDesc ? 'desc' : 'asc';

                this.isbusy = true;
                this.getItems(data);
            },
            emptyText: bus.$t('project.noIncome'),
            items: [],
            fields: [
                {
                    key: 'createdAt',
                    label: this.$t('h1.date'),
                    tdClass: 'align-middle',
                    thClass: '',
                },
                {
                    key: 'project',
                    label: this.$t('h1.project'),
                    tdClass: 'align-middle',
                    thClass: '',
                },
                {
                    key: 'account',
                    label: this.$t('finance.bankAccount'),
                    tdClass: 'align-middle',
                    thClass: '',
                },
                {
                    key: 'price',
                    label: this.$t('finance.onePaymentSum'),
                    tdClass: 'align-middle',
                    thClass: '',
                },
                {
                    key: 'profit',
                    label: this.$t('project.profit'),
                    tdClass: 'align-middle',
                    thClass: '',
                },
                {
                    key: 'status',
                    label: this.$t('project.status'),
                    tdClass: 'align-middle',
                    thClass: '',
                },
            ],
            locale: locale,
        };
    },

    mounted() {
        this.getItems = _.debounce((data = {}) => {
            let f = this.parseFilters();

            if (f.find) {
                let s = _.find(
                    STATUS,
                    st =>
                        st.titleRU.toLowerCase() == f.find.toLowerCase() ||
                        st.titleEN.toLowerCase() == f.find.toLowerCase()
                );
                if (s && s.id) f.find = s.id;
            }

            this.get(_.extend(f, { page: this.currentPage, itemsPerPage: this.perPage }, data))
                .then(async v => {
                    this.isbusy = false;
                    if (this.currentPage == 1) this.items = [];
                    this.items = this.items.concat(v);
                    this.$emit('onGetItems', { amount: this.items.length, type: 'payments' });

                    let projects = [];
                    let p_ids = _.uniq(
                        _.map(
                            _.filter(this.items, ii => ii.project),
                            i => (_.isString(i.project) ? _.last(i.project.split('/')) : i.project.id)
                        )
                    );

                    if (p_ids.length > 0)
                        projects = await this.getProjects({
                            id: p_ids,
                        });

                    this.$store.commit('finance/setPaymentsParams', { projects });
                })
                .catch(v => (this.isbusy = false));
        }, 500);
        bus.$on('tableFinanceIncomeRefresh', query => {
            this.searching(query);
        });

        this.searching();
    },
    created() {
        this.searching = _.debounce(query => {
            this.filters.orSearch_query = query != '' ? { id: query } : null;
            this.sorting(this);
        }, 400);
    },
    destroyed() {
        bus.$off('tableFinanceIncomeRefresh');
        this.$store.commit('finance/setIncomeTotal', 0);
    },
    computed: {
        ...mapGetters({
            totalItems: 'finance/incomeTotal',
            isPending: 'finance/isPendingIncome',
            projects: 'project/items',
        }),
    },
    methods: {
        ...mapActions({
            get: 'finance/incomeShort',
            getProjects: 'project/items',
        }),
        localSorting(ctx) {},
        statusVariant(item) {
            if (item.isRequested() || item.isChecked()) return 'text-orange';
            if (item.isRefund()) return 'text-orange';
            if (item.isPaid()) return 'text-success';
            if (item.isError()) return 'text-error';

            return '';
        },
    },
};
</script>
